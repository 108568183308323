import createDataContext from "./createContext";
import { SpedizioniService } from "../api";
import { useUrlClient } from "../hooks";
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";

const CARICO_MERCE = "carico_merce";
const VIAGGIO = "viaggio";
const INFO_VIAGGIO = "info_viaggio";
const INFO_COLLI = "info_colli";
const COLONNE = "colonne";
const VIAGGIO_BCODE = "viaggio_bcode";
const MESSAGE_DIALOG = "message_dialog";
const COLLI_QUALITA = "colli_qualita";

const INITIAL_STATE = {
  caricoMerce: [],
  viaggio: [],
  infoViaggio: [],
  infoColli: [],
  columns: [],
  messageDialog: "",
  listaColliQ:[],
};

const caricoMerceUscitaReducer = (state, action) => {
  switch (action.type) {
    case CARICO_MERCE: {
      return { ...state, caricoMerce: action.payload };
    }
    case VIAGGIO: {
      return {
        ...state,
        viaggio: action.payload.spedizioni,
        infoViaggio: action.payload,
      };
    }
    case INFO_VIAGGIO: {
      return { ...state, infoViaggio: action.payload };
    }
    case INFO_COLLI: {
      return { ...state, infoColli: action.payload };
    }
    case COLONNE: {
      return { ...state, columns: action.payload };
    }
    case VIAGGIO_BCODE: {
      return {
        ...state,
        viaggio: action.payload.barcode,
        infoViaggio: action.payload.infoText,
      };
    }
    case MESSAGE_DIALOG: {
      return { ...state, messageDialog: action.payload };
    }
    case COLLI_QUALITA: {
      const { listaColliQ } = action.payload
      return {
        ...state,
        listaColliQ
      };
    }
    default: {
      return state;
    }
  }
};

const getCaricoMerce = (dispatch) => async (date) => {
  dispatch({ type: CARICO_MERCE, payload: [] });
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/viaggi-del-giorno/${date}`
  );
  if (response.data) {
    dispatch({ type: CARICO_MERCE, payload: response.data.viaggio });
    dispatch({ type: COLONNE, payload: response.data.columns });
  }
};

const updateSpedizione =
  (dispatch) => async (prgviaggio, prgspedizione, body, setOpen) => {
    const response = await SpedizioniService.put(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/azione/carico/${prgviaggio}/${prgspedizione}`,
      {
        codiceevento: body.codiceevento,
        note: body.note,
        NotaViaggio_Autista: body.NotaViaggio_Autista,
      }
    );
    if (response.data) {
      getViaggio(dispatch)(prgviaggio);
      setOpen(false);
    }
  };

const getViaggio = (dispatch) => async (prgViaggio) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi/viaggio-per-carico/${prgViaggio}`
  );
  if (response.data) {
    dispatch({ type: VIAGGIO, payload: response.data });
  }
};

const getInfoColli = (dispatch) => async (prgspedizione) => {
  dispatch({ type: INFO_COLLI, payload: [] });
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/spedizioni/spedmerce/${prgspedizione}`
  );
  if (response.data) {
    dispatch({ type: INFO_COLLI, payload: response.data.spedmerce });
  }
};

// Partenza per distribuzione.
const getPartenzaDistribuzione =
  (dispatch) => async (prgViaggioBarcode, navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
    );
    if (response.data) {
      navigate(`/partenza-distribuzione/${response.data.PrgViaggio}`);
    }
  };

const getPartenzaDistribuzioneData =
  (dispatch) => async (PrgViaggio, navigate) => {
    const response = await SpedizioniService.get(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/partenza-distribuzione/${PrgViaggio}`
    );
    if (response.data) {
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: VIAGGIO_BCODE,
        payload: { barcode: response.data.data, infoText: response.data },
      });
    }
  };
const postPartenzaDistribuzione =
  (dispatch) => async (code, prgmovimento, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/viaggi/partenza-distribuzione/${parseInt(code)}/${riferimpaletta}`
    );
    //console.log('response: ', response);
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok Elemento Caricato Correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getPartenzaDistribuzioneData(dispatch)(code);
  };

// Partenza per trazione.
const getPartenzaTrazione =
  (dispatch) => async (prgViaggioBarcode, navigate) => {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
    );
    if (response.data) {
      navigate(`/partenza-per-trazione/${response.data.PrgViaggio}`);
    }
  };

const getPartenzaPerTrazioneData =
  (dispatch) => async (PrgViaggio, navigate) => {
    try{
      const response = await SpedizioniService.get(
        `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/${PrgViaggio}`
      );
      if (response.data) {
        dispatch({ type: COLONNE, payload: response.data.columns });
        dispatch({
          type: VIAGGIO_BCODE,
          payload: { barcode: response.data.data, infoText: response.data },
        });
      }
    }
    catch{
      navigate("/partenza-per-trazione")
    }
    
    
  };

const postPartenzaPerTrazione =
  (dispatch) => async (code, prgmovimento, riferimpaletta, setModalApprove) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/partenza-trazione/${parseInt(
        code
      )}/${riferimpaletta}`
    );
    //console.log('response: ', response);
    if (response.data) {
      if (response.data.ControllaSeBarCodeSpedGiaLetto !== 0) {
        /*  sendNotification(
        <ErrorTitle service={'OK!'} sound={"error"} />,
        response.data.Message,
        "warning",
        2
      ); */
        dispatch({ type: MESSAGE_DIALOG, payload: response.data });
        setModalApprove(true);
      } else {
        sendNotification(
          <ErrorTitle service={"OK!"} sound={"success"} />,
          response.data.Message,
          "success",
          5
        );
      }
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getPartenzaPerTrazioneData(dispatch)(code);
  };

// Arrivo per trazione.
const getArrivoTrazione = (dispatch) => async (prgViaggioBarcode, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/viaggi?barcode=${prgViaggioBarcode}`
  );
  if (response.data) {
    navigate(`/arrivo-da-trazione/${response.data.PrgViaggio}`);
  }
};

const getArrivoPerTrazioneData = (dispatch) => async (PrgViaggio, navigate) => {
  try {
    const response = await SpedizioniService.get(
      `${(await useUrlClient()).baseUrl}/viaggi/arrivo-trazione/${PrgViaggio}`
    );
    if (response.data) {
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: VIAGGIO_BCODE,
        payload: { barcode: response.data.data, infoText: response.data },
      });
    }
  } catch (error) {
    navigate("/arrivo-da-trazione");
  }
};

const postArrivoPerTrazione =
  (dispatch) => async (code, prgmovimento, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/viaggi/arrivo-trazione/${parseInt(
        code
      )}/${riferimpaletta}`
    );
    //console.log('response: ', response);
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok Elemento Caricato Correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getArrivoPerTrazioneData(dispatch)(code);
  };

const getColliQualita = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/controllo/barcode/${code}`
  );
  if (response.data) {
    navigate(
      `/cerca-colli-controllo-qualita/${response.data.campichiave.PrgControllo}`
    );
  }
};

const getRicercaColliQualita = (dispatch) => async () => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/controllo/ricerca`
    );
    if(response.data){
      dispatch({ type: COLONNE, payload: response.data.columns });
      dispatch({
        type: COLLI_QUALITA,
        payload: { listaColliQ: response.data.data },
      });
    }
};

const getControlloColloData = (dispatch) => async (PrgControllo, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/controllo/${PrgControllo}`
  );
  if (response.data) {
    dispatch({ type: COLONNE, payload: response.data.columns });
    dispatch({
      type: VIAGGIO_BCODE,
      payload: { barcode: response.data.data, infoText: response.data },
    });
  }
};

const postControlloColloData =
  (dispatch) => async (code, prgControllo, barcode) => {
    const response = await SpedizioniService.put(
      `${(await useUrlClient()).baseUrl}/magmovim/controllo/record`,
      {
        PrgControllo: prgControllo,
        Barcode: barcode,
      }
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"OK!"} sound={"success"} />,
        "Ok Elemento Caricato Correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getControlloColloData(dispatch)(code);
  };

export const { Provider, Context } = createDataContext(
  caricoMerceUscitaReducer,
  {
    getCaricoMerce,
    getViaggio,
    updateSpedizione,
    getInfoColli,
    getPartenzaTrazione,
    getPartenzaPerTrazioneData,
    postPartenzaPerTrazione,
    getArrivoTrazione,
    getArrivoPerTrazioneData,
    postArrivoPerTrazione,
    getPartenzaDistribuzione,
    getPartenzaDistribuzioneData,
    postPartenzaDistribuzione,
    getColliQualita,
    getControlloColloData,
    postControlloColloData,
    getRicercaColliQualita
  }, // actions
  INITIAL_STATE // initial state
);
